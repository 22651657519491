const coursesReducer = (
    state= {
        courses: [{name: "", credits: "", achieved: ""}],
        loading: false,
        message: null
    }
, action) => {
    switch (action.type) {
        case 'UPDATE_COURSES':
            return {
                ...state, courses: action.value
            }
        case 'SET_LOADING':
            return {
                ...state, loading: action.value
            }
        case 'SET_MESSAGE':
            return {
                ...state, message: action.value
            }
        case 'UPDATE_COURSE':
            return { 
                ...state, 
                courses: state.courses.map(
                    (content, i) => i === action.index ? action.value : content
                )
            }
        default:
            return state
    }
}

export default coursesReducer;