import React from 'react';
import ByCreditWidget from './ByCreditWidget';
import { connect } from 'react-redux';
import { CircularProgress, Grid } from '@material-ui/core';

const Home = ({loading}) => {   
    return <div className="page-container" style={{textAlign: "center"}}>
        <div style={{marginLeft: 30, marginTop: 10, textAlign: "left"}}>
            <Grid container direction="row">
                <Grid item>
                    <img src="calculator.png" alt="Cartoon drawn style calculator" height='60px'/>
                </Grid>
                <Grid item style={{marginLeft: 10}}>
                    <h1>
                        Course Calculator 
                    </h1> 
                </Grid>
            </Grid>
        </div>  

        <div style={{margin:'auto'}}>
            <iframe title='amazon-ad-1' src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=48&l=ur1&category=amazon_student&banner=159PP9MQ6JYSJZKDC6G2&f=ifr&linkID=e7b17e8ef42d21604f112e2be7b321a4&t=coursecalcula-21&tracking_id=coursecalcula-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style={{border:'none'}} frameborder="0"></iframe>        
        </div>

        {loading ? 
            <div>
                <Grid container direction="column">
                    <Grid item>
                        <CircularProgress />
                    </Grid>
                    <Grid item>
                        Calculating your results...
                    </Grid>
                </Grid>
            </div>
            :
            <ByCreditWidget/>
        }
        <p>By using this tool you are agreeing to our <a href="https://www.iubenda.com/privacy-policy/34986384">Privacy Policy</a></p> 

        <div style={{margin:'auto'}}>
            <iframe title='amazon-ad-2' src="https://rcm-eu.amazon-adsystem.com/e/cm?o=2&p=22&l=ur1&category=piv&banner=162N5PEPRMMJ87Z5G3G2&f=ifr&linkID=31b0497a48fea9b4c37ae4f9825c552a&t=coursecalcula-21&tracking_id=coursecalcula-21" width="250" height="250" scrolling="no" border="0" marginwidth="0" style={{border:'none'}} frameborder="0"></iframe>
        </div>
    </div>
}

function mapStateToProps({coursesReducer}) {
    return {
        loading: coursesReducer.loading
    }
}

export default connect(mapStateToProps)(Home);