import { Typography, Button } from "@material-ui/core";

const Results = ({message, dispatch}) => {
    return (
        <div>
            <Typography>
                {message}
            </Typography>
            <Button color="primary" variant="outlined" onClick={() => dispatch({type: "SET_MESSAGE", value: null})}>
                Recalculate
            </Button>
        </div>
    );
}

export default Results;