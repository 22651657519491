import { Button, TextField, Grid, Typography, Tooltip } from '@material-ui/core';
import React, {useState} from 'react';
import { connect } from 'react-redux';
import CreditCourseDetails from './CreditCourseDetails';
import Results from './Results';
import HelpIcon from '@material-ui/icons/Help';
import { green, grey } from '@material-ui/core/colors';


const ByCreditWidget = ({courses, message, dispatch}) => {
    const [desiredGrade, setDesiredGrade] = useState("");
    const [desiredGradeError, setDesiredGradeError] = useState(null);
    const [error, setError] = useState(null);

    const onChange = (index, data) => {
        dispatch({type: "UPDATE_COURSE", index, value: data});
    };

    const addCourse = () => {
        dispatch({type: "UPDATE_COURSES", value: [...courses, {name: "", credits: "", achieved: ""}]});
    };

    const removeCourse = (index) => {
        const updatedCourseList = courses.filter((course, cIndex) => index !== cIndex);
        
        if (updatedCourseList.length === 0 || courses.length === 1) {
            dispatch({type: "UPDATE_COURSES", value: [{name: "", credits: "", achieved: ""}]});
        } else { 
            dispatch({type: "UPDATE_COURSES", value: updatedCourseList});
        }
    }

    const wait = ms => new Promise(res => setTimeout(res, ms));

    const validate = () => {
        let errorFound = false;

        const emptyAchieveds = courses.filter(course => course.achieved === "");
        const emptyCredits = courses.filter(course => course.credits === "");

        if((desiredGrade === "" || null) && emptyAchieveds.length > 0){
            errorFound = true;
            setDesiredGradeError("Desired Grade is Required")
        }

        if(emptyCredits.length > 0) {
            errorFound = true;
            setError("You must add the number of credits for each of your courses for us to calculate your grade!")
        }

        if(emptyCredits.length === courses.length && emptyAchieveds.length === courses.length){
            errorFound = true;
            setError("Please add atleast one course for us to calculate your grade.");
            setDesiredGradeError(null);
        }

        return errorFound;
    };

    const onDesiredGradeChange = (e) => {
        const val = e.target.value;

        if(parseInt(val) > 100){
            setDesiredGrade("100");
        }
        else if(parseInt(val) < 0) {
            setDesiredGrade("0");
        }
        else {
            setDesiredGrade(val);
        }
    }

    const calculate = async () => {
        const valError = validate();

        if(valError){
            return null;
        }

        dispatch({type: "SET_MESSAGE", value: null});
        dispatch({type: "SET_LOADING", value: true});

        // waits 5 seconds
        await wait(5000);

        let totalCredits = 0;
        let creditsAchieved = 0;
        let totalPossibleAchieved = 0;
        let creditsUnachieved = 0;
        let notAchieved = [];
        courses.forEach((course) => {
            totalCredits += parseFloat(course.credits);
            if(course.achieved != null && course.achieved !== ""){
                creditsAchieved += parseFloat((course.achieved / 100) * course.credits);
                totalPossibleAchieved += parseFloat(course.credits);
            }
            else {
                notAchieved.push(course);
                creditsUnachieved += parseFloat(course.credits);
            }
        });
        let achievedPercentage = ((creditsAchieved / totalCredits) * 100).toFixed(2);
        let percentageSoFar = ((creditsAchieved / totalPossibleAchieved) * 100).toFixed(2);

        if(notAchieved.length === 0){
            dispatch({type: "SET_LOADING", value: false});
            dispatch({type: "SET_MESSAGE", value: `You've got ${achievedPercentage}% overall. Kickback, relax and have a beer`});
        }
        else if(achievedPercentage >= desiredGrade){
            dispatch({type: "SET_LOADING", value: false});
            dispatch({type: "SET_MESSAGE", value: `No need to worry, you already got ${achievedPercentage}% overall. Kickback, relax and have a beer`});
        } else {
            let requiredCredits = totalCredits * (desiredGrade / 100);
            let neededCredits = requiredCredits - creditsAchieved;
            if(creditsUnachieved < neededCredits) {
                dispatch({type: "SET_LOADING", value: false});
                dispatch({type: "SET_MESSAGE", value: "Sorry but this grade is unachievable :/"});
            }
            else {
                let coursePercentage = ((neededCredits / creditsUnachieved) * 100).toFixed(2);
                dispatch({type: "SET_LOADING", value: false});
                if(percentageSoFar === "NaN"){
                    dispatch({type: "SET_MESSAGE", value: `Get an average of ${coursePercentage}% in all your courses and you'll get ${desiredGrade}% overall!`});
                }
                else {
                    dispatch({type: "SET_MESSAGE", value: `So far you've got an average of ${percentageSoFar}% across your courses. Get an average of ${coursePercentage}% in all your remaining courses and you'll get ${desiredGrade}% overall!`});
                }
            }   
        }
    };

    const isMobile = window.innerWidth < 480;
    const screenWidth = isMobile ? "80%" : "40%"; 
    const direction = isMobile ? "column" : "row"; 

    return <div style={{display:'flex', flexDirection:'column', alignItems:'center', width: screenWidth, margin: "auto", marginTop: 20}}>
        {
            message ? 
                <Results message={message} dispatch={dispatch}/>
                :
                <div>
                    <Grid container direction="column" justify="center">
                        <Grid item style={{textAlign: "left", marginLeft: 5}}>
                            <p>Welcome to the Course Calculator. If you're looking to calculate your overall grade on all your modules or work out what
                                you need to get in your remaining ones you're in the right place! Simply enter the details for your courses
                                below to calculate!
                            </p>
                            <strong>If you want to know what you need to get in certain courses leave the achieved field blank.</strong>
                        </Grid>
                        <div style={{marginTop: 15, backgroundColor: '#ffffff', borderRadius: "15px", width: '100%',}}>
                            <Grid item style={{marginTop: 20, width: "100%"}}>
                                {courses.map((course, index) => 
                                    <CreditCourseDetails course={course} index={index} arrayLength={courses.length} onChange={onChange} onClose={removeCourse}/>
                                )}
                            </Grid>
                            <Grid item>
                                <Button onClick={() => addCourse()} variant="contained" style={{color: grey[50], padding:'10px 30px', margin:'20px', backgroundColor: grey[500], borderRadius:'50px'}}>
                                    Add Course
                                </Button>
                            </Grid>
                            {error && <Grid item>
                                <Typography style={{color: "red"}}>
                                    {error}
                                </Typography>
                            </Grid>}
                            <Grid item container sm direction={direction} justify={isMobile ? "center" : "flex-end"} alignItems="center">
                                <Grid item>
                                    {/* <p>Desired Overall Grade can be left blank if you're just calculating your results</p> */}
                                    <TextField type="number" value={desiredGrade} id="course" label="Desired Grade (%)" variant="outlined" 
                                        onChange={onDesiredGradeChange} error={desiredGradeError != null} helperText={desiredGradeError} 
                                        InputProps={{
                                            endAdornment: <Tooltip title={"Desired Grade can be left blank if you're just calculating your results"}><HelpIcon color="disabled"/></Tooltip>
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Button className="Button" onClick={() => calculate()} variant="contained" color="secondary" style={{padding:'10px 30px', margin:'20px', backgroundColor: green[500], borderRadius:'50px'}}>
                                        Calculate
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </div>
        }
    </div>
};

function mapStateToProps({coursesReducer}) {
    return {
        courses: coursesReducer.courses,
        message: coursesReducer.message
    }
}

export default connect(mapStateToProps)(ByCreditWidget);