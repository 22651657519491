import React from 'react';
import {Link} from 'react-router-dom';

//shows if the page requested doesnt exist
export const NoMatch = () => {
    return (
        <div className="center">
            <p><strong className="error-text">This page doesn't exist!</strong></p>
            <p><Link to="/">Return to homepage</Link></p>
        </div>
    );
};