import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './components/homepage/Home';
import { NoMatch } from './components/NoMatch'
import './App.css';
import { Provider } from 'react-redux';
import store from './redux/store';

require('dotenv').config()

function App() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route component={NoMatch} />
        </Switch>
      </Provider>
    </React.Fragment>
  );
}

export default App;
