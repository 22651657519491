import React from 'react';
import { TextField, Fab, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { red, grey } from '@material-ui/core/colors';

const CreditCourseDetails = ({course, index, arrayLength, onChange, onClose}) => {
    const handleChange = (e, key) => {
        const val = e.target.value;
        let data = {name: course.name, credits: course.credits, achieved: course.achieved};

        if(key === "name"){
            data[key] = val;
        }
        else {
            if(val === "" || val == null){
                data[key] = "";
            } 
            else if (parseInt(val) > 100 && key === "achieved") {
                data[key] = "100";
            } 
            else if (parseInt(val) < 0){
                data[key] = "0";
            } 
            else {
                data[key] = val;
            }
        }
        
        onChange(index, data);
    }

    const deleteCourse = () => {
        onClose(index);
    }

    const margin = index === 0 ? 0 : 10;

    const isMobile = window.innerWidth < 480;
    const direction = isMobile ? "column" : "row"; 

    return (
            <Grid xs item container direction={direction} justify="center" spacing={2} style={{marginTop: margin}}>
                <Grid item >
                    <TextField value={course.name} inputProps={{style: {width: 150}}} id="course" label="Course Name" variant="outlined" onChange={(e) => handleChange(e, "name")} />
                </Grid>
                <Grid item>
                    <TextField type="number" inputProps={{style: {width: 150}}} value={course.credits} id="course" label="Num Credits" variant="outlined" onChange={(e) => handleChange(e, "credits")}/>
                </Grid>
                <Grid item>
                    <TextField type="number" inputProps={{style: {width: 150}}} value={course.achieved} id="course" label="Achieved (%)" variant="outlined" onChange={(e) => handleChange(e, "achieved")}/>
                </Grid>
                <Grid item>
                    <Fab style={{marginTop: 5, borderColor: red[800], borderWidth: "1px", backgroundColor: grey[50]}} backgroundColor="white" size="small" aria-label="remove-course" onClick={() => deleteCourse()}>
                        <CloseIcon style={{color: red[800]}}/>
                    </Fab>
                </Grid>
            </Grid>
    );
}

export default CreditCourseDetails;